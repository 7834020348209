import { styled } from '@mui/system'
import { Collapse } from '@mui/material'
import Typography from '@bluheadless/ui/src/atoms/typography'

const shouldForwardProp = (prop) => !['animate', 'hasOpacity', 'aboveTheFold'].includes(prop)

const Root = styled('div')`
	height: ${({ aboveTheFold }) => (aboveTheFold ? 'inherit' : undefined)};
`

const TextContainer = styled('div', { shouldForwardProp })`
	${({ hasOpacity }) =>
		hasOpacity
			? `
				&:before {
					background-color: rgba(0, 0, 0, 0.2);
					content: '';
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					transition: 0.5s ease-in-out;
					width: 100%;
				}
			`
			: null}
	${({ theme }) => theme.breakpoints.up('md')} {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		&.shady:before {
			background-color: rgba(0, 0, 0, 0.5);
		}
	}
`

const TextContainer2 = styled('div')`
	${({ theme }) => theme.breakpoints.up('md')} {
		position: relative;
	}
`

const TitleContainer = styled('div')`
	${({ theme }) => theme.breakpoints.down('md')} {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding-bottom: ${({ theme }) => theme.spacing(1.5)};
	}
`

const Title = styled(Typography)`
	font-weight: ${({ theme }) => theme.typography.fontWeightBold};
	text-align: center;
	${({ theme }) => theme.breakpoints.down('md')} {
		padding: ${({ theme }) => theme.spacing(0, 3)};
	}
`

const ContentContainer = styled('div', { shouldForwardProp })`
	${({ theme }) => theme.breakpoints.down('md')} {
		background-color: ${({ theme }) => theme.palette.common.white};
		height: 100%;
		padding: ${({ theme }) => theme.spacing(3.75, 3)};
		position: absolute;
		top: 0;
		transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
		width: 100%;
		z-index: 1;
		&.animated {
			box-shadow: 10px 0px 20px #00000038;
		}
		${({ animate }) =>
			animate === 'left'
				? `left:-200%;
				&.animated {
					transform: translateX(100%);
				}
				`
				: `
				left:100%;
				transform: translateX(100%);
				&.animated {
					transform: translateX(0);
				}
		`};
	}
	ul {
		margin-bottom: 0;
		margin-top: 0;
		padding-left: 0;
	}
	li {
		display: block;
		padding: ${({ theme }) => theme.spacing(1.5, 0)};
		${({ theme }) => theme.breakpoints.up('md')} {
			padding: ${({ theme }) => theme.spacing(0.15, 0)};
		}
	}
	a {
		text-decoration: none;
		${({ theme }) => theme.linkHover};
		${({ theme }) => theme.breakpoints.up('md')} {
			color: ${({ theme }) => theme.palette.common.white};
		}
	}
`

const CollapseStyled = styled(Collapse)`
	opacity: 0;
	transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 50ms ease;
	${({ theme }) => theme.breakpoints.down('md')} {
		${({ theme }) => theme.scrollbar};
		height: 100%;
		max-height: calc(100% - 46px);
		overflow-y: auto;
		.MuiCollapse-wrapper {
			height: 100%;
		}
	}
	&.MuiCollapse-entered {
		opacity: 1;
	}
`

const CloseWrapper = styled('div')`
	${({ theme }) => theme.breakpoints.down('md')} {
		background-color: ${({ theme }) => theme.palette.common.white};
		bottom: 0;
		padding: ${({ theme }) => theme.spacing(2.5, 3)};
		position: absolute;
		text-align: right;
		width: ${({ theme }) => 'calc(100% - ' + theme.spacing(6) + ')'};
	}
`

export { CloseWrapper, CollapseStyled, ContentContainer, Root, TextContainer, TextContainer2, Title, TitleContainer }
